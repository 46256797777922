.game-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #252b39;
    height: 100vh;

    /* No text selection to improve gameplay */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.game-page-logged-out {
    width: 100%;
    display: flex;
    flex-direction: column;
    /*background-image: url(../static/images/GameImages/GameBackground/Season5Day.png);*/
    background-size: cover;
    height: 100vh;

    /* No text selection to improve gameplay */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.game-page-grayed {
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100vh !important;
}

.game-page-reward-text {
    max-width: 90%;
    width: contain;
    height: auto;
}

.game-page-text {
    margin-top: 60px !important;
    text-align: center;
    color: #252b39 !important;
}

.game-page-login-button {
    margin: 10px !important;
}

.game-page-cast-button {
    margin-bottom: 5px !important;
}

.game-page-cast-timer {
    width: 150px !important;
}

.game-page-cast-container {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    position: absolute;
    height: calc(100vh - 80px);
}

.game-page-cast-container-condensed {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    position: absolute;
    height: calc(((100vh * 5) / 6) - 80px);
}

.game-page-cast-container-grid {
    position: fixed; 
    align-items: center;
    margin-bottom: 25px !important;
}

.game-page-bait-selection {
    margin-left: 10px !important;
    margin-bottom: 5px !important;
    min-width: 80px !important;
    background-color: #3465b0 !important;
    color: white !important;
    border-radius: 5px !important;
}

.game-page-bait-selection-input {
    color: white !important; 
    border-radius: 5px !important;
    border-bottom: none !important; 
    background-color: none !important;
}

.game-page-sub-text {
    text-align: center;
    color: #252b39 !important;
}

.buy-casts-modal-button {
    margin: 20px !important;
}

.buy-casts-modal-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.display-reward-modal-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin: 20px !important;
}

.game-page-reward-grow {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    position: absolute !important;
    width: 100% !important;
    height: calc(100vh - 80px) !important;
}

.game-page-fish-remaining-container {
    display: flex !important;
    justify-content: flex-start !important;
    justify-content: center !important;
    align-items: center !important;
}

.game-page-season-info-container {
    display: flex !important;
    justify-content: flex-start;
    justify-content: center !important;
    align-items: center !important;
}

.game-page-fish-remaining-text {
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    padding: 6px !important;
    background-color: #6690cb !important;
    color: white !important;
    border-radius: 5px !important;
}

.game-page-taps-time-container {
    display: flex !important;
    text-align: center;
    align-items: center;
    position: absolute;
    width: 100%;
}

.game-page-taps-time {
    display: flex;
    align-items: center;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-top: 90px !important;
    background-color: #6690cb !important;
    color: white;
    border-radius: 5px !important;
}

.game-page-bait-menu-item {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.game-page-bait-menu-item-text {
    flex-grow: 1;
}

.game-page-fish-remaining-breakdown-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.game-page-fish-remaining-breakdown-divider {
    flex-grow: 1;
    margin: 10px !important;
    padding: 2px;
}

.game-page-buttons-bottom-align {
    align-self: flex-end;
}

.game-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}