.game-menu-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.game-menu-stats-container {
    padding: 5px !important;
}

.game-menu-stats-text {
    text-align: left;
    margin-top: 5px !important;
}

.game-menu-stats-value {
    text-align: right;
    margin-top: 5px !important;
}

.game-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.game-menu-rod-main-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.game-menu-rod-button {
    width: 150px;
}

.game-menu-rod-container {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px !important;
    flex-direction: column;
    padding: 10px !important
}

.game-menu-rod-image {
    height: 300px;
}

.game-menu-boat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px !important;
    flex-direction: column;
    padding: 10px !important
}

.game-menu-boat-button {
    max-width: 300px !important;
}

.game-menu-boat-image {
    width: 100%;
    margin: 5px;
}

.game-menu-typography-white {
    color: white;
}

.game-menu-changing-pole-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100% !important;
}

.game-menu-help-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
