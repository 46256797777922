.fish-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.fish-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;   
}

.fish-page-image {
    max-width: 300px;
    width: 100%;
}

.fish-page-content-divider {
    margin-top: 2px !important;
    margin-bottom: 5px !important;
    padding: 1px;
}

.fishdex-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.fishdex-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;   
}