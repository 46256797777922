.fish-tank-page {
  position: fixed !important;
  z-index: -1 !important;

  background-image: url('../static/images/background.png');
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100% !important;
  height: 100vh !important;
}

.fish-tank-canvas {
  position: absolute !important;
}
