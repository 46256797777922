.wof-text-center {
    text-align: center;
}

.wof-spin-button {
    width: 100%;
}

.wof-wheel-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.market-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.market-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.market-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    width: 100%;
}