.profile-referrals-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-referrals-stepper-icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 50% !important;
    padding: 18px !important;
    margin-top: -5px !important;
    color: white !important;
}

.profile-referrals-modal-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.profile-referrals-copy-button {
    margin: 10px !important;
    max-width: 10px !important;
}

.profile-referrals-code-text {
    border: solid #3465b0;
    border-radius: 5px !important;
    padding: 5px !important;
}

.profile-referals-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}

.profile-referrals-info-modal-content {
    text-align: center;
}