.profile-etherbait-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-etherbait-list {
    width: 100% !important;
}

.profile-etherbait-empty {
    text-align: center;
}

.profile-etherbait-list-item {
    /*margin-left: 30px !important; */
}

.profile-etherbait-buy-list-item {
    list-style-type: none !important;
}

.profile-etherbait-buy-list-item-title {
    display: flex;
    align-items: center;
}

.profile-etherbait-buy-list-item-count {
    color: rgba(0, 0, 0, 0.54) !important;
}

.collectible-grid-item {
    align-items: center;
    justify-content: center;
}

.market-grid-container {
    align-items: center;
    justify-content: center;

}

.collectible-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}

.market-bait-buy-button {
    margin: 5px !important
}