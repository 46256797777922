.change-password {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.change-password-paper {
    text-align: center;
}

.change-password-text {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.change-password-error-message {
    text-align: left;
    width: 300px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 20px !important;
    word-wrap: break-word;
}

.change-password-input {
    width: 300px;
    margin: 30px !important;
}

.change-password-submit-button {
    width: 300px;
    margin: 30px !important;
}