.species-and-traits-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.species-and-traits-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.species-and-traits-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.species-and-traits-page-summary-container {
    text-align: center;
}

.species-and-traits-page-summary-text-disabled {
    color:gray;
}

.species-and-traits-page-summary-list-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    border:#252b39 solid;
    border-radius: 20px !important;
    padding: 10px;
    height: 100%;
    background-color: #6690cb;
}

.species-and-traits-page-summary-list-container {
    text-align: left;
}

.species-and-traits-page-summary-list-grid {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.species-and-traits-page-opensea-button {
    margin: 10px !important;
}

.species-and-traits-page-team-image {
    border-radius: 5% !important;
    width: 100% !important;
}

.species-and-traits-page-team-text {
    text-align: start;
}

.species-and-traits-page-team-text-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}

.species-and-traits-page-team-text-title-devs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.species-and-traits-page-team-text-title-role {
    background-color: #6690cb !important;
    padding: 3px !important;
    border-radius: 5px !important;
}

.species-and-traits-page-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 10px !important;
    padding: 20px !important;
}


.species-and-traits-page-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.species-and-traits-page-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.species-and-traits-page-social-icons-email {
    color: white !important;
    background-color: rgb(242, 63, 63) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.species-and-traits-page-animation-artist-image {
    width: 200px;
    max-width: 200px;
    border-radius: 5% !important;
}

.species-and-traits-page-contributors-grid {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.species-and-traits-app-bar {
    height: 60px;
}

.species-and-traits-tab-panel {
    width: 100%;
    height: 100%;
    max-height: 600px;
    padding: 0px !important;
}