.home-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.home-page-logo-grid {
    display: flex;
    align-content: center;
    justify-content: center;
}

.home-page-logo {
    width: 100% !important;
    height: 100% !important;
    max-width: 470px;
    max-height: 470px;
    border-radius: 10% !important;
}

.home-page-social-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.home-page-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 5px !important;
}


.home-page-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
}

.home-page-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
}

.home-page-social-icons-opensea {
    background-color: #2081E2 !important;
    margin: 5px !important;
}

.home-page-etherfishing-summary-text {
    font-size: 1.35rem !important;
    width: 100%;
}

.home-page-etherfishing-summary-divider {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding: 1px;
}

.home-page-content-title {
    text-align: center !important;
}

.home-page-content-title-disabled {
    text-align: center !important;
    color: gray;
}

.home-page-content-title-main {
    text-align: center !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-page-content-title-text {
    text-align: center;
}

.home-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.home-page-content-news {
    text-align: center !important;
    align-items: center;
    display: flex;
    justify-content: center;
}

.home-page-top-catch-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.home-page-top-catch-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-top-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    margin: 5px !important;
    padding: 10px !important;
}

.home-top-image {
    width: 270px !important;
}

.home-square-ad-image {
    width: 450px !important;
}

.home-page-roadmap-step-content {
    font-size: 1.35rem !important;
}

.home-page-giveaway-image {
    width: 100%;
}

.home-page-news-image {
    width: 100%;
}

.home-page-text-button-container {
    display: flex;
    align-items: center;
}

.home-page-help-button {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.youtube-video {
    width: 100%;
    max-width: 560px;
    height: 315px;
}

.home-page-link {
    color: #3465b0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}