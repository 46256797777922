.promo-code-container {
    width: 100% !important;
    margin: 5px !important;
}

.promo-code-input {
    width: 100% !important;
}

.promo-code-button {
    width: 100% !important;
}