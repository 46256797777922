.verify-email-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}

.verify-email-button {
    margin: 5px !important;
}