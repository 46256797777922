p {
    margin: 0;
    font-size: 12pt;
}

li {
    font-size: 12pt;
}

ul.lst-kix_list_5-0 {
    list-style-type: none;
}

ol {
    margin: 0;
    padding: 0;
}

.c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 12pt;
}

.c1 {
    width: 33%;
    height: 1px;
}

.c2 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
}

.c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.c4 {
    font-weight: 700;
}

.c5 {
    padding: 0;
    margin: 0;
}

.c6 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
}

.c7 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: italic;
}

.c8 {
    margin-left: 36pt;
    padding-left: 0pt;
}

.c9 {
    margin-left: 36pt;
    padding-left: 0pt;
}

.c10 {
    margin-left: 36pt;
}

.c11 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
}

.c12 {
    padding-left: 0pt;
}

.c13 {
    margin-left: 36pt;
}

.c14 {
    font-size: 16pt;
}

.c15 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-style: normal;
}

.c16 {
    color: #3465b0;
}

.c17 {
    color: inherit;
    text-decoration: inherit;
}

.c18 {
    background-color:#ffffff;
    max-width:468pt;
    padding:72pt 72pt 72pt 72pt
}

.c19 {
    vertical-align: super;
}
