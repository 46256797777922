.profile-avatar {
    width: 250px !important;
    height: 250px !important;
    border-radius: 10% !important;
}

.profile-avatar-button {
    border-radius: 10% !important;
    padding: 0px !important;
}

.profile-avatar-edit {
    width: 250px !important;
    height: 250px !important;
    background-color: black;
    border-radius: 10%;
    padding: 70px;
    color: white;
    position: absolute;
}

.profile-avatar-edit:hover {
    visibility: visible !important;
    opacity: 0.5 !important;
}

.profile-avatar-modal-content { 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding-right: 70px !important;
    padding-left: 70px !important;
    overflow-x: hidden;
}

.profile-avatar-modal-content-upload { 
    margin: 20px !important;
}

.profile-avatar-upload-error {
    text-align: left;
    max-width: 300px;
    margin-bottom: 30px !important;
    word-wrap: break-word;
}
