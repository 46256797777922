.NavbarItems {
    height: 80px;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-family: 'Londrina Solid';
    letter-spacing: 1.5px;
    padding-left: 50px;
    padding-right: 50px;
}

.navbar-spacer {
    flex-grow: 1;
}

.navbar-home {
    display: flex;
}

.navbar-home-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-self: start;
    cursor: pointer;
}

.navbar-home-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.navbar-home-text {
    color: #fff;
    float: right;
    margin: 7px !important;
    font-family: 'Londrina Solid';
    letter-spacing: 1.5px;
}

.navbar-home-drawer {
    color: #6690cb;
}

.navbar-burger-menu-icon {
    color: white;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #6690cb;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #ffffff;
}

nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
        padding-left: 5px;
        padding-right: 5px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .navbar-home-text {
        font-size: 2rem;
    }

    .nav-menu.active {
        background: #6690cb;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        font-family: 'Londrina Solid';
        letter-spacing: 1.5px;
    }

    .nav-links:hover {
        background-color: #6690cb;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #ffffff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background-color: #4ad9e4;
        text-decoration: none;
        color: #ffffff;
        font-size: 1.5rem;
    }
}
