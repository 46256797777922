@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
.home-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.home-page-logo-grid {
    display: flex;
    align-content: center;
    justify-content: center;
}

.home-page-logo {
    width: 100% !important;
    height: 100% !important;
    max-width: 470px;
    max-height: 470px;
    border-radius: 10% !important;
}

.home-page-social-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.home-page-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 5px !important;
}


.home-page-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
}

.home-page-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
}

.home-page-social-icons-opensea {
    background-color: #2081E2 !important;
    margin: 5px !important;
}

.home-page-etherfishing-summary-text {
    font-size: 1.35rem !important;
    width: 100%;
}

.home-page-etherfishing-summary-divider {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding: 1px;
}

.home-page-content-title {
    text-align: center !important;
}

.home-page-content-title-disabled {
    text-align: center !important;
    color: gray;
}

.home-page-content-title-main {
    text-align: center !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-page-content-title-text {
    text-align: center;
}

.home-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.home-page-content-news {
    text-align: center !important;
    align-items: center;
    display: flex;
    justify-content: center;
}

.home-page-top-catch-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.home-page-top-catch-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-top-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    margin: 5px !important;
    padding: 10px !important;
}

.home-top-image {
    width: 270px !important;
}

.home-square-ad-image {
    width: 450px !important;
}

.home-page-roadmap-step-content {
    font-size: 1.35rem !important;
}

.home-page-giveaway-image {
    width: 100%;
}

.home-page-news-image {
    width: 100%;
}

.home-page-text-button-container {
    display: flex;
    align-items: center;
}

.home-page-help-button {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.youtube-video {
    width: 100%;
    max-width: 560px;
    height: 315px;
}

.home-page-link {
    color: #3465b0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
.footer-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.footer-summary-container {
    text-align: center;
}

.footer-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 10px !important;
    padding: 20px !important;
}


.footer-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.footer-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.footer-social-icons-email {
    color: white !important;
    background-color: rgb(242, 63, 63) !important;
    margin: 5px !important;
    padding: 20px !important;
}
.about-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.about-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.about-page-summary-container {
    text-align: center;
}

.about-page-summary-text-disabled {
    color:gray;
}

.about-page-summary-list-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    border:#252b39 solid;
    border-radius: 20px !important;
    padding: 10px;
    height: 100%;
    background-color: #6690cb;
}

.about-page-summary-list-container {
    text-align: left;
}

.about-page-summary-list-grid {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.about-page-opensea-button {
    margin: 10px !important;
}

.about-page-team-image {
    border-radius: 5% !important;
    width: 100% !important;
}

.about-page-team-text {
    text-align: start;
}

.about-page-team-text-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}

.about-page-team-text-title-devs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.about-page-team-text-title-role {
    background-color: #6690cb !important;
    padding: 3px !important;
    border-radius: 5px !important;
}

.about-page-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 10px !important;
    padding: 20px !important;
}


.about-page-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.about-page-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.about-page-social-icons-email {
    color: white !important;
    background-color: rgb(242, 63, 63) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.about-page-animation-artist-image {
    width: 200px;
    max-width: 200px;
    height: 200px;
    border-radius: 5% !important;
}

.about-page-contributors-grid {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
}

.about-page-sq-img {
    width: 270px !important;
    height: 270px !important;
}

.about-page-info-img {  
    width: auto;
}
.profile-avatar {
    width: 250px !important;
    height: 250px !important;
    border-radius: 10% !important;
}

.profile-avatar-button {
    border-radius: 10% !important;
    padding: 0px !important;
}

.profile-avatar-edit {
    width: 250px !important;
    height: 250px !important;
    background-color: black;
    border-radius: 10%;
    padding: 70px;
    color: white;
    position: absolute;
}

.profile-avatar-edit:hover {
    visibility: visible !important;
    opacity: 0.5 !important;
}

.profile-avatar-modal-content { 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding-right: 70px !important;
    padding-left: 70px !important;
    overflow-x: hidden;
}

.profile-avatar-modal-content-upload { 
    margin: 20px !important;
}

.profile-avatar-upload-error {
    text-align: left;
    max-width: 300px;
    margin-bottom: 30px !important;
    word-wrap: break-word;
}

.profile-bio-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-bio-textbox-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-bio-textbox-button-group {
    width: calc(100% - 30px);
}

.profile-bio-textbox-button {
    width: 100%;
}

.profile-bio {
    width: calc(100% - 30px);
}

.profile-bio-edit-button {
    text-transform: unset !important;
}

.profile-bio-edit-icon {
    margin-left: 5px;
}

.profile-bio-edit-icon-hidden {
    margin-right: 5px;
}

.verify-email-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}

.verify-email-button {
    margin: 5px !important;
}
.profile-options-button {
    margin: 5px !important;
}

.profile-options-icon {
    margin-right: 7px !important;
}

.profile-settings-content {
    display: flex;
    justify-content: center;
    margin: 10px !important;
    flex-direction: column;
}
.profile-enjin-wallet-button {
    width: 100%;
}

.profile-enjin-wallet-linkcode {
    text-align: center;
}

.profile-enjin-wallet-qr {
    height: 200px;
    width: 200px;
}

.profile-enjin-wallet-container {
    width: 100%;
    margin: 5px !important;
}

.profile-enjin-wallet-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:grey;
}

.profile-enjin-wallet-address-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - 105px);
}

.profile-enjin-wallet-modal-content {
    overflow-x: hidden;
    margin: 20px !important;
    display: flex;
    flex-direction: column;
}

.profile-enjin-wallet-input {
    flex-shrink: 1;
    width: 300px;
}

.profile-enjin-upload-error {
    text-align: left;
    max-width: 300px;
    width: 100%;
    margin-bottom: 5px !important;
    word-wrap: break-word;
}

.profile-enjin-verify-sub-text {
    max-width: 370px !important;
    text-align: center;
    margin: 10px !important;
}

.profile-enjin-verify-modal-content {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: column;
}

.profile-enjin-verify-modal-content-text {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: row;
}

.profile-opensea-wallet-container {
    width: 100%;
    margin: 5px !important;
}

.profile-opensea-wallet-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:grey;
}

.profile-opensea-wallet-address-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - 105px);
}

.profile-opensea-wallet-button {
    width: 100%;
}

.profile-opensea-wallet-modal-content {
    overflow-x: hidden;
    margin: 20px !important;
    display: flex;
    flex-direction: column;
}

.profile-opensea-wallet-input {
    flex-shrink: 1;
    width: 300px;
}

.profile-opensea-upload-error {
    text-align: left;
    max-width: 300px;
    width: 100%;
    margin-bottom: 5px !important;
    word-wrap: break-word;
}

.profile-opensea-verify-sub-text {
    max-width: 370px !important;
    text-align: center;
    margin: 10px !important;
}

.profile-opensea-verify-modal-content {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: column;
}

.profile-opensea-verify-modal-content-text {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: row;
}

.profile-opensea-wallet-container {
    width: 100%;
    margin: 5px !important;
}

.profile-opensea-wallet-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:grey;
}

.profile-opensea-wallet-address-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - 105px);
}

.profile-opensea-wallet-button {
    width: 100%;
}

.profile-opensea-wallet-modal-content {
    overflow-x: hidden;
    margin: 20px !important;
    display: flex;
    flex-direction: column;
}

.profile-opensea-wallet-input {
    flex-shrink: 1;
    width: 300px;
}

.profile-opensea-upload-error {
    text-align: left;
    max-width: 300px;
    width: 100%;
    margin-bottom: 5px !important;
    word-wrap: break-word;
}

.profile-opensea-verify-sub-text {
    max-width: 370px !important;
    text-align: center;
    margin: 10px !important;
}

.profile-opensea-verify-modal-content {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: column;
}

.profile-opensea-verify-modal-content-text {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: row;
}

.profile-options-button {
    justify-content: flex-end;
}

.profile-etherbait-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-etherbait-list {
    width: 100% !important;
}

.profile-etherbait-empty {
    text-align: center;
}

.profile-etherbait-list-item {
    /* margin-left: 30px !important; */
}

.profile-etherbait-buy-list-item {
    list-style-type: none !important;
}

.profile-etherbait-buy-list-item-title {
    display: flex;
    align-items: center;
}

.profile-etherbait-buy-list-item-count {
    color: rgba(0, 0, 0, 0.54) !important;
}

.collectible-grid-item {
    align-items: center;
    justify-content: center;
}

.collectible-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}
.profile-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.profile-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1000px;
}

.profile-loading-user-info-paper {
    position: absolute !important;
    border-radius: 0 !important;
    display: flex !important;
    width: 100% !important;
    height: 100vh !important;
    justify-content: center !important;
    opacity: 0.4 !important;
}

.profile-loading-user-info {
    margin: 150px !important;
}

.profile-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.profile-username-text {
    margin-top: 20px !important;
    word-break: break-all !important;
}

.profile-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.profile-user-account-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%
}

.profile-user-account-total-fish {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.profile-user-account-created {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.profile-items-title {
    display: flex;
    align-items: center;
}

.profile-items-buy-button {
    margin-left: 30px !important;
}

.profile-boat-stepper {
    width: 100%
}

.profile-stats-label {
    text-align: left;
}

.profile-stats-var {
    text-align: right;
    justify-content: flex-end;
}
.profile-stats-example {
    justify-content: center;
}

.profile-casts-info {
    text-align: center;
}

.profile-spins-container {
    align-items: center;
}

.profile-wof-button {
    width:100%;
}
.profile-appbar {
    z-index: inherit !important;
}

.red-text {
    color: red;
}

.blue-text {
    color: rgb(81, 81, 190);
}
.profile-collectibles-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.collectible-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collectible-image {
    width: 100% !important;
    max-width: 300px !important;
}
.profile-referrals-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-referrals-stepper-icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 50% !important;
    padding: 18px !important;
    margin-top: -5px !important;
    color: white !important;
}

.profile-referrals-modal-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.profile-referrals-copy-button {
    margin: 10px !important;
    max-width: 10px !important;
}

.profile-referrals-code-text {
    border: solid #3465b0;
    border-radius: 5px !important;
    padding: 5px !important;
}

.profile-referals-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}

.profile-referrals-info-modal-content {
    text-align: center;
}
.profile-Trophies-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.collectible-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collectible-image {
    width: 100% !important;
}

.profile-trophy-image {
    margin: 5px !important;
}

.promo-code-container {
    width: 100% !important;
    margin: 5px !important;
}

.promo-code-input {
    width: 100% !important;
}

.promo-code-button {
    width: 100% !important;
}
.button {
    width:100%;
}

.fit-picture {
    width: 250px;
}

.center-text {
    text-align: center;
}
.createAccount {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.createAccountPaper {
    text-align: center;
}

.createText {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.createAccountErrorMessage {
    text-align: left;
    width: 300px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 20px !important;
    word-wrap: break-word;
}

.inputCreateItem {
    width: 300px;
    margin: 30px !important;
}

.submitCreateButton {
    width: 300px;
    margin: 30px !important;
}

p {
    margin: 0;
    font-size: 12pt;
}

li {
    font-size: 12pt;
}

ul.lst-kix_list_5-0 {
    list-style-type: none;
}

ol {
    margin: 0;
    padding: 0;
}

.c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 12pt;
}

.c1 {
    width: 33%;
    height: 1px;
}

.c2 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
}

.c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
}

.c4 {
    font-weight: 700;
}

.c5 {
    padding: 0;
    margin: 0;
}

.c6 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
}

.c7 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: italic;
}

.c8 {
    margin-left: 36pt;
    padding-left: 0pt;
}

.c9 {
    margin-left: 36pt;
    padding-left: 0pt;
}

.c10 {
    margin-left: 36pt;
}

.c11 {
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
}

.c12 {
    padding-left: 0pt;
}

.c13 {
    margin-left: 36pt;
}

.c14 {
    font-size: 16pt;
}

.c15 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-style: normal;
}

.c16 {
    color: #3465b0;
}

.c17 {
    color: inherit;
    text-decoration: inherit;
}

.c18 {
    background-color:#ffffff;
    max-width:468pt;
    padding:72pt 72pt 72pt 72pt
}

.c19 {
    vertical-align: super;
}

.page {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login {
    text-align: center;
}

.loginText {
    margin-top: 30px !important;
}

.loginLink {
    text-decoration: none;
    color: inherit;
}

.loginLink:hover {
    text-decoration: underline;
    color: inherit;
}

.loginLinkMarg {
    text-decoration: none;
    color: white;
    margin-top: 30px !important;
}

.loginLinkMarg:hover {
    text-decoration: underline;
    color: white;
    margin-top: 30px !important;
}

.loginErrorMessage {
    text-align: left;
    width: 300px;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-top: 30px !important;
    word-wrap: break-word;
}

.username {
    width: 300px;
    margin: 20px !important;
}

.password {
    width: 300px;
    margin: 30px !important;
}

.submitButton {
    width: 300px;
    margin: 30px !important;
}
.forgotPassword {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.forgotPasswordPaper {
    text-align: center;
}

.forgotPasswordText {
    margin-top: 30px !important;
}

.forgotPasswordErrorMessage {
    text-align: left;
    width: 300px;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-top: 30px !important;
    word-wrap: break-word;
}

.emailReset {
    width: 300px;
    margin: 20px !important;
}

.submitEmailResetButton {
    width: 300px;
    margin: 30px !important;
}

.change-password {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.change-password-paper {
    text-align: center;
}

.change-password-text {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.change-password-error-message {
    text-align: left;
    width: 300px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 20px !important;
    word-wrap: break-word;
}

.change-password-input {
    width: 300px;
    margin: 30px !important;
}

.change-password-submit-button {
    width: 300px;
    margin: 30px !important;
}
.fish-tank-options {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.fish-tank-zen-button {
    margin: 20px !important;
    margin-bottom: 0pc !important;
}

.fish-tank-login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.fish-tank-page-grayed {
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100vh !important;
}

.fish-tank-login-text {
    margin-top: 60px !important;
}

.fish-tank-login-text {
    color: #252b39 !important;
}

.fish-tank-login-button {
    margin: 10px !important;
}

.fish-tank-loading-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    margin-top: 50px !important;
}

.fish-tank-loading-text {
    color: #252b39 !important;
}

.fish-tank-list-drawer {
    display: flex;
}

.fish-tank-list-card {
    margin: 15px !important;
    padding: 10px !important;
}

.fish-tank-list-button {
    text-transform: none !important;
    text-align: start;
    width: 100% !important;
    background: transparent !important;
}

.fish-tank-list-title-button {
    text-transform: none !important;
    justify-content: flex-start !important;
    width: 100% !important;
}

.fish-tank-list-item-title-input {
    width: 100% !important;
}

.fish-tank-list-image {
    width: 100%;
}

.fish-list-item-grid {
    text-align: center;
}

.fish-tank-search-input {
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.fish-tank-drawer-button {
    margin: 5px !important;
    color: white !important;
    background-color: #6690cb !important;
}

.fish-tank-background-image {
    max-width: 300px !important;
    margin: 10px !important;
    border-radius: 6%/10% !important;
}

.fish-tank-background-text {
    top: 30px !important;
    left: 30px !important;
    position: absolute !important;
    color: white !important;
}

.fish-tank-drawer-title {
    color: white;
    text-align: center;
}

.fish-tank-settings-body {
    padding: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fish-list-item-settings {
    display: flex;
    flex-direction: column;
}

.fish-list-item-settings-open-page {
    width: 100%;
}

.fish-list-item-settings-checkbox {
    width: 100%;
    padding: 5px;
}
.game-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #252b39;
    height: 100vh;

    /* No text selection to improve gameplay */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.game-page-logged-out {
    width: 100%;
    display: flex;
    flex-direction: column;
    /*background-image: url(../static/images/GameImages/GameBackground/Season5Day.png);*/
    background-size: cover;
    height: 100vh;

    /* No text selection to improve gameplay */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.game-page-grayed {
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100vh !important;
}

.game-page-reward-text {
    max-width: 90%;
    width: contain;
    height: auto;
}

.game-page-text {
    margin-top: 60px !important;
    text-align: center;
    color: #252b39 !important;
}

.game-page-login-button {
    margin: 10px !important;
}

.game-page-cast-button {
    margin-bottom: 5px !important;
}

.game-page-cast-timer {
    width: 150px !important;
}

.game-page-cast-container {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    position: absolute;
    height: calc(100vh - 80px);
}

.game-page-cast-container-condensed {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    position: absolute;
    height: calc(((100vh * 5) / 6) - 80px);
}

.game-page-cast-container-grid {
    position: fixed; 
    align-items: center;
    margin-bottom: 25px !important;
}

.game-page-bait-selection {
    margin-left: 10px !important;
    margin-bottom: 5px !important;
    min-width: 80px !important;
    background-color: #3465b0 !important;
    color: white !important;
    border-radius: 5px !important;
}

.game-page-bait-selection-input {
    color: white !important; 
    border-radius: 5px !important;
    border-bottom: none !important; 
    background-color: none !important;
}

.game-page-sub-text {
    text-align: center;
    color: #252b39 !important;
}

.buy-casts-modal-button {
    margin: 20px !important;
}

.buy-casts-modal-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.display-reward-modal-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin: 20px !important;
}

.game-page-reward-grow {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    position: absolute !important;
    width: 100% !important;
    height: calc(100vh - 80px) !important;
}

.game-page-fish-remaining-container {
    display: flex !important;
    justify-content: flex-start !important;
    justify-content: center !important;
    align-items: center !important;
}

.game-page-season-info-container {
    display: flex !important;
    justify-content: flex-start;
    justify-content: center !important;
    align-items: center !important;
}

.game-page-fish-remaining-text {
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    padding: 6px !important;
    background-color: #6690cb !important;
    color: white !important;
    border-radius: 5px !important;
}

.game-page-taps-time-container {
    display: flex !important;
    text-align: center;
    align-items: center;
    position: absolute;
    width: 100%;
}

.game-page-taps-time {
    display: flex;
    align-items: center;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-top: 90px !important;
    background-color: #6690cb !important;
    color: white;
    border-radius: 5px !important;
}

.game-page-bait-menu-item {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.game-page-bait-menu-item-text {
    flex-grow: 1;
}

.game-page-fish-remaining-breakdown-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.game-page-fish-remaining-breakdown-divider {
    flex-grow: 1;
    margin: 10px !important;
    padding: 2px;
}

.game-page-buttons-bottom-align {
    align-self: flex-end;
}

.game-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}
.game-menu-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.game-menu-stats-container {
    padding: 5px !important;
}

.game-menu-stats-text {
    text-align: left;
    margin-top: 5px !important;
}

.game-menu-stats-value {
    text-align: right;
    margin-top: 5px !important;
}

.game-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.game-menu-rod-main-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.game-menu-rod-button {
    width: 150px;
}

.game-menu-rod-container {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px !important;
    flex-direction: column;
    padding: 10px !important
}

.game-menu-rod-image {
    height: 300px;
}

.game-menu-boat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px !important;
    flex-direction: column;
    padding: 10px !important
}

.game-menu-boat-button {
    max-width: 300px !important;
}

.game-menu-boat-image {
    width: 100%;
    margin: 5px;
}

.game-menu-typography-white {
    color: white;
}

.game-menu-changing-pole-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100% !important;
}

.game-menu-help-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-menu-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 400px;
}

.game-menu-stats-container {
    padding: 5px !important;
}

.game-menu-stats-text {
    text-align: left;
    margin-top: 5px !important;
}

.game-menu-stats-value {
    text-align: right;
    margin-top: 5px !important;
}

.game-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.game-menu-rod-main-container { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.game-menu-rod-button {
    width: 150px;
}

.game-menu-rod-container {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px !important;
    flex-direction: column;
    padding: 10px !important
}

.game-menu-rod-image {
    height: 300px;
}

.game-menu-boat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px !important;
    flex-direction: column;
    padding: 10px !important
}

.game-menu-boat-button {
    max-width: 300px !important;
}

.game-menu-boat-image {
    width: 100%;
    margin: 5px;
}

.game-menu-typography-white {
    color: white;
}

.game-menu-changing-pole-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100% !important;
}

.game-menu-help-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.market-page-grayed {
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100vh !important;
}

.market-page-text {
    margin-top: 60px !important;
    text-align: center;
    color: #252b39 !important;
}

.market-page-button {
    margin: 5px !important;
}

.market-page-sub-text {
    text-align: center;
    color: #252b39 !important;
}

.market-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.market-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    width: 100%;
}

.market-loading-user-info-paper {
    position: absolute !important;
    border-radius: 0 !important;
    display: flex !important;
    width: 100% !important;
    height: 100vh !important;
    justify-content: center !important;
    opacity: 0.4 !important;
}

.market-loading-user-info {
    margin: 150px !important;
}

.market-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.market-username-text {
    margin-top: 20px !important;
    word-break: break-all !important;
}

.market-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.market-user-account-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%
}

.market-user-account-total-fish {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.market-user-account-created {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.market-items-title {
    display: flex;
    align-items: center;
}

.market-items-buy-button {
    width: 98%;
    height: 90%;
}

.market-boat-stepper {
    width: 100%
}

.market-stats-label {
    text-align: left;
}

.market-stats-var {
    text-align: right;
}

.market-casts-info {
    text-align: center;
}

.market-trade-status-button {
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 90%
}

.market-trade-status-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    overflow-y: auto;
}

.market-transaction-refresh {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    max-width:300px;
    padding-top: 15px;
}

.market-eth-help {
    text-align: right;
}

.market-enj-help {
    text-align: left;
}

.market-img {
    max-width: 95%;
    max-height: 95%;
}
.profile-etherbait-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-etherbait-list {
    width: 100% !important;
}

.profile-etherbait-empty {
    text-align: center;
}

.profile-etherbait-list-item {
    /*margin-left: 30px !important; */
}

.profile-etherbait-buy-list-item {
    list-style-type: none !important;
}

.profile-etherbait-buy-list-item-title {
    display: flex;
    align-items: center;
}

.profile-etherbait-buy-list-item-count {
    color: rgba(0, 0, 0, 0.54) !important;
}

.collectible-grid-item {
    align-items: center;
    justify-content: center;
}

.market-grid-container {
    align-items: center;
    justify-content: center;

}

.collectible-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}

.market-bait-buy-button {
    margin: 5px !important
}
.profile-etherbait-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-etherbait-list {
    width: 100% !important;
}

.profile-etherbait-empty {
    text-align: center;
}

.profile-etherbait-buy-list-item {
    list-style-type: none !important;
}

.profile-etherbait-buy-list-item-title {
    display: flex;
    align-items: center;
}

.profile-etherbait-buy-list-item-count {
    color: rgba(0, 0, 0, 0.54) !important;
}

.collectible-grid-item {
    align-items: center;
    justify-content: center;
}

.collectible-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}
.profile-etherbait-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.profile-etherbait-list {
    width: 100% !important;
}

.profile-etherbait-empty {
    text-align: center;
}

.profile-etherbait-buy-list-item {
    list-style-type: none !important;
}

.profile-etherbait-buy-list-item-title {
    display: flex;
    align-items: center;
}

.profile-etherbait-buy-list-item-count {
    color: rgba(0, 0, 0, 0.54) !important;
}

.collectible-grid-item {
    align-items: center;
    justify-content: center;
}

.collectible-grid-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%
}
.help-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.help-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.help-page-content-title {
    text-align: center !important;
}

.help-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.help-page-link {
    color: #3465b0;
}

.help-page-list {
    padding-left: 30px; 
    padding-right: 30px;
}
.species-and-traits-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.species-and-traits-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.species-and-traits-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.species-and-traits-page-summary-container {
    text-align: center;
}

.species-and-traits-page-summary-text-disabled {
    color:gray;
}

.species-and-traits-page-summary-list-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    border:#252b39 solid;
    border-radius: 20px !important;
    padding: 10px;
    height: 100%;
    background-color: #6690cb;
}

.species-and-traits-page-summary-list-container {
    text-align: left;
}

.species-and-traits-page-summary-list-grid {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.species-and-traits-page-opensea-button {
    margin: 10px !important;
}

.species-and-traits-page-team-image {
    border-radius: 5% !important;
    width: 100% !important;
}

.species-and-traits-page-team-text {
    text-align: start;
}

.species-and-traits-page-team-text-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}

.species-and-traits-page-team-text-title-devs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.species-and-traits-page-team-text-title-role {
    background-color: #6690cb !important;
    padding: 3px !important;
    border-radius: 5px !important;
}

.species-and-traits-page-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 10px !important;
    padding: 20px !important;
}


.species-and-traits-page-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.species-and-traits-page-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.species-and-traits-page-social-icons-email {
    color: white !important;
    background-color: rgb(242, 63, 63) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.species-and-traits-page-animation-artist-image {
    width: 200px;
    max-width: 200px;
    border-radius: 5% !important;
}

.species-and-traits-page-contributors-grid {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.species-and-traits-app-bar {
    height: 60px;
}

.species-and-traits-tab-panel {
    width: 100%;
    height: 100%;
    max-height: 600px;
    padding: 0px !important;
}
.fish-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.fish-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;   
}

.fish-page-image {
    max-width: 300px;
    width: 100%;
}

.fish-page-content-divider {
    margin-top: 2px !important;
    margin-bottom: 5px !important;
    padding: 1px;
}

.fishdex-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.fishdex-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;   
}
.wof-text-center {
    text-align: center;
}

.wof-spin-button {
    width: 100%;
}

.wof-wheel-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.market-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.market-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.market-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    width: 100%;
}
.home-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.home-page-logo-grid {
    display: flex;
    align-content: center;
    justify-content: center;
}

.home-page-logo {
    width: 100% !important;
    height: 100% !important;
    max-width: 470px;
    max-height: 470px;
    border-radius: 10% !important;
}

.home-page-social-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.home-page-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 5px !important;
}


.home-page-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
}

.home-page-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
}

.home-page-social-icons-opensea {
    background-color: #2081E2 !important;
    margin: 5px !important;
}

.home-page-etherfishing-summary-text {
    font-size: 1.35rem !important;
    width: 100%;
}

.home-page-etherfishing-summary-divider {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding: 1px;
}

.home-page-content-title {
    text-align: center !important;
}

.home-page-content-title-disabled {
    text-align: center !important;
    color: gray;
}

.home-page-content-title-main {
    text-align: center !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-page-content-title-text {
    text-align: center;
}

.home-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.home-page-content-news {
    text-align: center !important;
    align-items: center;
    display: flex;
    justify-content: center;
}

.home-page-top-catch-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.home-page-top-catch-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-top-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    margin: 5px !important;
    padding: 10px !important;
}

.home-top-image {
    width: 270px !important;
}

.home-square-ad-image {
    width: 450px !important;
}

.home-page-roadmap-step-content {
    font-size: 1.35rem !important;
}

.home-page-giveaway-image {
    width: 100%;
}

.home-page-news-image {
    width: 100%;
}

.home-page-text-button-container {
    display: flex;
    align-items: center;
}

.home-page-help-button {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.youtube-video {
    width: 100%;
    max-width: 560px;
    height: 315px;
}

.home-page-link {
    color: #3465b0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.NavbarItems {
    height: 80px;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-family: 'Londrina Solid';
    letter-spacing: 1.5px;
    padding-left: 50px;
    padding-right: 50px;
}

.navbar-spacer {
    flex-grow: 1;
}

.navbar-home {
    display: flex;
}

.navbar-home-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-self: start;
    cursor: pointer;
}

.navbar-home-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.navbar-home-text {
    color: #fff;
    float: right;
    margin: 7px !important;
    font-family: 'Londrina Solid';
    letter-spacing: 1.5px;
}

.navbar-home-drawer {
    color: #6690cb;
}

.navbar-burger-menu-icon {
    color: white;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #6690cb;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #ffffff;
}

nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
        padding-left: 5px;
        padding-right: 5px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .navbar-home-text {
        font-size: 2rem;
    }

    .nav-menu.active {
        background: #6690cb;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        font-family: 'Londrina Solid';
        letter-spacing: 1.5px;
    }

    .nav-links:hover {
        background-color: #6690cb;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #ffffff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background-color: #4ad9e4;
        text-decoration: none;
        color: #ffffff;
        font-size: 1.5rem;
    }
}

.user-agreement-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px !important;
}
.fish-tank-page {
  position: fixed !important;
  z-index: -1 !important;

  background-image: url(/static/media/background.299e62b6.png);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100% !important;
  height: 100vh !important;
}

.fish-tank-canvas {
  position: absolute !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

