.footer-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.footer-summary-container {
    text-align: center;
}

.footer-social-icons-instagram {
    color: white !important;
    background-color: rgb(225, 48, 108) !important;
    margin: 10px !important;
    padding: 20px !important;
}


.footer-social-icons-twitter {
    color: white !important;
    background-color: rgb(29, 161, 242) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.footer-social-icons-discord {
    color: white !important;
    background-color: rgb(114, 137, 218) !important;
    margin: 5px !important;
    padding: 20px !important;
}

.footer-social-icons-email {
    color: white !important;
    background-color: rgb(242, 63, 63) !important;
    margin: 5px !important;
    padding: 20px !important;
}