.market-page-grayed {
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100vh !important;
}

.market-page-text {
    margin-top: 60px !important;
    text-align: center;
    color: #252b39 !important;
}

.market-page-button {
    margin: 5px !important;
}

.market-page-sub-text {
    text-align: center;
    color: #252b39 !important;
}

.market-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.market-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    width: 100%;
}

.market-loading-user-info-paper {
    position: absolute !important;
    border-radius: 0 !important;
    display: flex !important;
    width: 100% !important;
    height: 100vh !important;
    justify-content: center !important;
    opacity: 0.4 !important;
}

.market-loading-user-info {
    margin: 150px !important;
}

.market-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.market-username-text {
    margin-top: 20px !important;
    word-break: break-all !important;
}

.market-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.market-user-account-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%
}

.market-user-account-total-fish {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.market-user-account-created {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.market-items-title {
    display: flex;
    align-items: center;
}

.market-items-buy-button {
    width: 98%;
    height: 90%;
}

.market-boat-stepper {
    width: 100%
}

.market-stats-label {
    text-align: left;
}

.market-stats-var {
    text-align: right;
}

.market-casts-info {
    text-align: center;
}

.market-trade-status-button {
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 90%
}

.market-trade-status-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    overflow-y: auto;
}

.market-transaction-refresh {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    max-width:300px;
    padding-top: 15px;
}

.market-eth-help {
    text-align: right;
}

.market-enj-help {
    text-align: left;
}

.market-img {
    max-width: 95%;
    max-height: 95%;
}