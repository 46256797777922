.help-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.help-page-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1200px;
}

.help-page-content-title {
    text-align: center !important;
}

.help-page-content-divider {
    margin: 10px !important;
    padding: 1px;
}

.help-page-link {
    color: #3465b0;
}

.help-page-list {
    padding-left: 30px; 
    padding-right: 30px;
}