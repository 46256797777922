.button {
    width:100%;
}

.fit-picture {
    width: 250px;
}

.center-text {
    text-align: center;
}