.forgotPassword {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.forgotPasswordPaper {
    text-align: center;
}

.forgotPasswordText {
    margin-top: 30px !important;
}

.forgotPasswordErrorMessage {
    text-align: left;
    width: 300px;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-top: 30px !important;
    word-wrap: break-word;
}

.emailReset {
    width: 300px;
    margin: 20px !important;
}

.submitEmailResetButton {
    width: 300px;
    margin: 30px !important;
}
