.profile-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.profile-paper {
    margin: 20px !important;
    padding: 30px !important;
    display: flex;
    max-width: 1000px;
}

.profile-loading-user-info-paper {
    position: absolute !important;
    border-radius: 0 !important;
    display: flex !important;
    width: 100% !important;
    height: 100vh !important;
    justify-content: center !important;
    opacity: 0.4 !important;
}

.profile-loading-user-info {
    margin: 150px !important;
}

.profile-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.profile-username-text {
    margin-top: 20px !important;
    word-break: break-all !important;
}

.profile-divider {
    width: calc(100% - 20px);
    margin: 10px !important;
}

.profile-user-account-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%
}

.profile-user-account-total-fish {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.profile-user-account-created {
    margin: 10px !important;
    color: grey;
    flex-grow: 1;
}

.profile-items-title {
    display: flex;
    align-items: center;
}

.profile-items-buy-button {
    margin-left: 30px !important;
}

.profile-boat-stepper {
    width: 100%
}

.profile-stats-label {
    text-align: left;
}

.profile-stats-var {
    text-align: right;
    justify-content: flex-end;
}
.profile-stats-example {
    justify-content: center;
}

.profile-casts-info {
    text-align: center;
}

.profile-spins-container {
    align-items: center;
}

.profile-wof-button {
    width:100%;
}
.profile-appbar {
    z-index: inherit !important;
}

.red-text {
    color: red;
}

.blue-text {
    color: rgb(81, 81, 190);
}