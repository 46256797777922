.page {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login {
    text-align: center;
}

.loginText {
    margin-top: 30px !important;
}

.loginLink {
    text-decoration: none;
    color: inherit;
}

.loginLink:hover {
    text-decoration: underline;
    color: inherit;
}

.loginLinkMarg {
    text-decoration: none;
    color: white;
    margin-top: 30px !important;
}

.loginLinkMarg:hover {
    text-decoration: underline;
    color: white;
    margin-top: 30px !important;
}

.loginErrorMessage {
    text-align: left;
    width: 300px;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-top: 30px !important;
    word-wrap: break-word;
}

.username {
    width: 300px;
    margin: 20px !important;
}

.password {
    width: 300px;
    margin: 30px !important;
}

.submitButton {
    width: 300px;
    margin: 30px !important;
}