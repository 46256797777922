.profile-enjin-wallet-button {
    width: 100%;
}

.profile-enjin-wallet-linkcode {
    text-align: center;
}

.profile-enjin-wallet-qr {
    height: 200px;
    width: 200px;
}

.profile-enjin-wallet-container {
    width: 100%;
    margin: 5px !important;
}

.profile-enjin-wallet-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:grey;
}

.profile-enjin-wallet-address-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - 105px);
}

.profile-enjin-wallet-modal-content {
    overflow-x: hidden;
    margin: 20px !important;
    display: flex;
    flex-direction: column;
}

.profile-enjin-wallet-input {
    flex-shrink: 1;
    width: 300px;
}

.profile-enjin-upload-error {
    text-align: left;
    max-width: 300px;
    width: 100%;
    margin-bottom: 5px !important;
    word-wrap: break-word;
}

.profile-enjin-verify-sub-text {
    max-width: 370px !important;
    text-align: center;
    margin: 10px !important;
}

.profile-enjin-verify-modal-content {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: column;
}

.profile-enjin-verify-modal-content-text {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: row;
}
