.fish-tank-options {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.fish-tank-zen-button {
    margin: 20px !important;
    margin-bottom: 0pc !important;
}

.fish-tank-login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.fish-tank-page-grayed {
    background-color: rgb(255, 255, 255, .3) !important;
    background-size: cover;
    width: 100% !important;
    height: 100vh !important;
}

.fish-tank-login-text {
    margin-top: 60px !important;
}

.fish-tank-login-text {
    color: #252b39 !important;
}

.fish-tank-login-button {
    margin: 10px !important;
}

.fish-tank-loading-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    margin-top: 50px !important;
}

.fish-tank-loading-text {
    color: #252b39 !important;
}

.fish-tank-list-drawer {
    display: flex;
}

.fish-tank-list-card {
    margin: 15px !important;
    padding: 10px !important;
}

.fish-tank-list-button {
    text-transform: none !important;
    text-align: start;
    width: 100% !important;
    background: transparent !important;
}

.fish-tank-list-title-button {
    text-transform: none !important;
    justify-content: flex-start !important;
    width: 100% !important;
}

.fish-tank-list-item-title-input {
    width: 100% !important;
}

.fish-tank-list-image {
    width: 100%;
}

.fish-list-item-grid {
    text-align: center;
}

.fish-tank-search-input {
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.fish-tank-drawer-button {
    margin: 5px !important;
    color: white !important;
    background-color: #6690cb !important;
}

.fish-tank-background-image {
    max-width: 300px !important;
    margin: 10px !important;
    border-radius: 6%/10% !important;
}

.fish-tank-background-text {
    top: 30px !important;
    left: 30px !important;
    position: absolute !important;
    color: white !important;
}

.fish-tank-drawer-title {
    color: white;
    text-align: center;
}

.fish-tank-settings-body {
    padding: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fish-list-item-settings {
    display: flex;
    flex-direction: column;
}

.fish-list-item-settings-open-page {
    width: 100%;
}

.fish-list-item-settings-checkbox {
    width: 100%;
    padding: 5px;
}