.profile-options-button {
    margin: 5px !important;
}

.profile-options-icon {
    margin-right: 7px !important;
}

.profile-settings-content {
    display: flex;
    justify-content: center;
    margin: 10px !important;
    flex-direction: column;
}