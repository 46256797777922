.profile-bio-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-bio-textbox-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-bio-textbox-button-group {
    width: calc(100% - 30px);
}

.profile-bio-textbox-button {
    width: 100%;
}

.profile-bio {
    width: calc(100% - 30px);
}

.profile-bio-edit-button {
    text-transform: unset !important;
}

.profile-bio-edit-icon {
    margin-left: 5px;
}

.profile-bio-edit-icon-hidden {
    margin-right: 5px;
}
