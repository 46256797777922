.profile-Trophies-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.collectible-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collectible-image {
    width: 100% !important;
}

.profile-trophy-image {
    margin: 5px !important;
}
