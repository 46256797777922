.profile-collectibles-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
}

.collectible-grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collectible-image {
    width: 100% !important;
    max-width: 300px !important;
}